
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
  // IonSlides,
  // IonSlide,
  // IonSegment,
  // IonSegmentButton,
  // IonicSlides,
  // IonAvatar,
} from "@ionic/vue";
import BtnSpinner from "@/components/spinner.vue";
import { panStatus, verifyPan } from "@/store/categories";
import { useStore } from "@nanostores/vue";
import { addNewCustomer,customerpanKyc,customeraadharKyc, panKycStore, aadharKycStore } from "@/store/customers";
import { Field, Form } from "vee-validate";
import { formatDDMMYYYY, formatYYYYMMDD } from "../services/date-format";
import {
  me,
  fetchMe,
  userbyphone,
  fetchMeDetails,
  user,
  CheckByPhone,
  updateCustomerDetails,
  CheckByEmail,
  userbyemail,
} from "@/store/users";
import { onMounted } from "vue";
import * as yup from 'yup';

const userstore = useStore(user);
const meData = useStore(me);
const storeuserbyphone = useStore(userbyphone);
const storeuserbyemail = useStore(userbyemail);
const storepan = useStore(panKycStore);
const storeaddhar = useStore(aadharKycStore);

export default defineComponent({
  name: "CustomerDetailPage",
  // components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonBackButton, IonButtons, IonSlides, IonSlide, IonSegment, IonSegmentButton, Swiper, SwiperSlide, Tabs, Tab, TabPanels, TabPanel },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonBackButton,
    IonButtons,
    BtnSpinner,
    Field,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    // IonSlides,
    // IonSlide,
    // IonSegment,
    // IonSegmentButton,
    // IonAvatar,
  },

  setup() {
    const storePanStatus = useStore(panStatus);
    // const mailregexp = ^[a-z0-9](\.?[a-z0-9]){5,}@g(oogle)?mail\.com$;
    const schema = yup.object({
      phone: yup.string().matches(new RegExp('[0-9]{10}')).max(10),
      email: yup.string().matches(new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')),

});
    onMounted(async () => {
      await fetchMe();
    });
    return {
      storePanStatus,
      meData,
      schema,
      storeuserbyphone,
      storeuserbyemail,
      userstore,
      storepan,
      storeaddhar
    };
  },

  data() {
    return {
      dateofbirth: "",
      userGroup: {},
      userProject: {
      },
      formatDDMMYYYY,
      formatYYYYMMDD,
      step: 3,
      // fullName: "",
      userDetails: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        dialCode: "+91",
        pincode: "",
        dob: "",
        isOnBoard: true,
        panCard: "",
        gender:"",
        aadharCard:"",
        salariedType: "",
        salariedAmount: "",
        id: "",
        usermetaid: "",
        usermeta: [{}],
        addressLine1:'',
        addressid:'',
        userprojectid:'',
        csm: localStorage.getItem("me"),
      },
      usermetapincodeid:"",
      usermetasalamtid:"",
      usermetasalatypemtid:"",
      agreed: false,
      otp: null,
      verifying: false,
      isLoading: false,
      isverified: true,
      isverifiedpan: true,
      isverifiedaddhar: true,
      useravailable: false,
      buttonchange: false,
      buttonchangeaddhar: false,
      buttonchangepan: false,
    };
  },
  watch: {
    // fullName(val) {
    //   const names = val.split(" ");
    //   this.userDetails.firstName = names[0];
    //   this.userDetails.lastName =
    //     names.length > 1 ? names[names.length - 1] : "";
    // },
  },
  methods: {
    isRequired(value: any) {
      return value ? true : "This field is required";
    },
   async verifypankyc(value: any) {
      await customerpanKyc({ card: value });
      this.isverifiedaddhar = true;
      console.log("this.storepan", this.storepan);
      if(this.storepan === 'error'){
        this.buttonchangepan = false;
        this.isverifiedaddhar = true;
        alert("Not able to verify the Pan No. Please try again!!!");
      }else{
        this.buttonchangepan = true;
        this.isverifiedaddhar = false;
        this.isverified = false;
      }
    },
   async verifyaadharkyc(value: any) {
      await customeraadharKyc({ card: value });
      this.isverified = true;
      if(this.storeaddhar === 'error'){
        this.buttonchangeaddhar = false;
        this.isverified = true;
        alert("Not able to verify the Aadhar No. Please try again!!!");
      }else{
        this.buttonchangeaddhar = true;
        this.isverified = false;
      }
    },
    async Checkphone(phone: string) {
      if (phone === this.meData.phoneNo) {
        alert("You cannot use your own phone number to create a customer");
      } else {
        await CheckByPhone({ phoneNo: phone });
        console.log("this.storeuserbyphone", this.storeuserbyphone);
        if (this.storeuserbyphone) {
          await fetchMeDetails({sk: this.storeuserbyphone?.id});
          this.useravailable = true;
          this.buttonchange = true;
          this.isverifiedpan = false;
          // this.buttonchangepan = true;
        // this.isverifiedaddhar = false;
        // this.buttonchangeaddhar = true;
        this.isverified = false;
          this.userDetails.addressLine1= this.userstore?.userProject[0]?.Address[0]?.addressLine1;
          this.userDetails.addressid= this.userstore?.userProject[0]?.Address[0]?.id;
          this.userDetails.userprojectid= this.userstore?.userProject[0]?.id;
          this.userDetails.firstName = this.userstore?.firstName;
          this.userDetails.lastName = this.userstore?.lastName;
          this.dateofbirth = this.userstore?.dob
            .split("-")
            .reverse()
            .join("-");
          this.userDetails.email = this.userstore.email;
          this.userDetails.panCard = this.userstore.panCard;
          this.userDetails.gender = this.userstore.gender;
          this.userDetails.aadharCard = this.userstore.aadharCard;
          this.userstore?.userMeta?.forEach((element: any) => {
            if(element?.metaKeyProject === "801c8b62-d7ea-4089-b569-804dbfec2f83"){
              this.userDetails.pincode = element?.value;
              this.usermetapincodeid = element?.id;
              this.userDetails.usermetaid = element?.id;
            }
            if(element?.metaKeyProject === "6ca5791c-63b2-4005-8486-2e84d180b536"){
              this.userDetails.salariedAmount = element?.value;
              this.usermetasalamtid = element?.id;
            }
            if(element?.metaKeyProject === "ecc79135-687a-4b3d-8d5c-158910900208"){
              this.userDetails.salariedType = element?.value;
              this.usermetasalatypemtid = element?.id;
            }
            // if(element?.metaKeyProject === "801c8b62-d7ea-4089-b569-804dbfec2f83"){
            //   this.userDetails.pincode = element?.value;
            // }
          });
          this.userDetails.salariedType = this.userstore.salariedType;
          this.userDetails.id = this.userstore.id;
        } else {
          this.buttonchange = true;
          this.isverifiedpan = false;
          this.isverified = false;
        }
      }
      // await verifyPan(phone);
      // this.isverifiedpan = false;
    },
    verifyMobile() {
      this.verifying = true;
      setTimeout(() => {
        this.verifying = false;
        this.step += 1;
      }, 1200);
    },
    async onSubmit() {
      console.log(this.userDetails, "uu");
      this.userDetails.dob = formatDDMMYYYY(this.dateofbirth);
      await CheckByEmail({ emailId: this.userDetails.email });
      console.log("this.storeuserbyemail", this.storeuserbyemail);
      this.isLoading = true;
      if (this.useravailable) {
        console.log("update");
        if (!this.storeuserbyemail) {
          this.userDetails.usermeta =[{
          where:{id:this.usermetapincodeid},
          data:{
            value:{
              set:this.userDetails.pincode
            },
            ProjectMetaKey:{
              connect:{
                id:"801c8b62-d7ea-4089-b569-804dbfec2f83"
              }
            }
          }
        },
        {
          where:{id:this.usermetasalamtid},
          data:{
            value:{
              set:this.userDetails.salariedAmount
            },
            ProjectMetaKey:{
              connect:{
                id:"6ca5791c-63b2-4005-8486-2e84d180b536"
              }
            }
          }
        },{
          where:{id:this.usermetasalatypemtid},
          data:{
            value:{
              set:this.userDetails.salariedType
            },
            ProjectMetaKey:{
              connect:{
                id:"ecc79135-687a-4b3d-8d5c-158910900208"
              }
            }
          }
        }]
          await updateCustomerDetails(this.userDetails);
          this.$router.push({ path: "/dashboard/customers" });
          this.useravailable = false;
        } else {
          if(this.storeuserbyemail.email === this.userDetails.email) {
            this.userDetails.usermeta =[{
          where:{id:this.usermetapincodeid},
          data:{
            value:{
              set:this.userDetails.pincode
            },
            ProjectMetaKey:{
              connect:{
                id:"801c8b62-d7ea-4089-b569-804dbfec2f83"
              }
            }
          }
        },
        {
          where:{id:this.usermetasalamtid},
          data:{
            value:{
              set:this.userDetails.salariedAmount
            },
            ProjectMetaKey:{
              connect:{
                id:"6ca5791c-63b2-4005-8486-2e84d180b536"
              }
            }
          }
        },{
          where:{id:this.usermetasalatypemtid},
          data:{
            value:{
              set:this.userDetails.salariedType
            },
            ProjectMetaKey:{
              connect:{
                id:"ecc79135-687a-4b3d-8d5c-158910900208"
              }
            }
          }
        }]
          await updateCustomerDetails(this.userDetails);
          this.$router.push({ path: "/dashboard/customers" });
          this.useravailable = false
          }else{
          alert("Emailid alredy exist please try another Emailid");
          }
        }
      } else {
        if (!this.storeuserbyemail) {
          this.userDetails.usermeta =[{
            value:this.userDetails.pincode,
            ProjectMetaKey:{
              connect:{
                id:"801c8b62-d7ea-4089-b569-804dbfec2f83"
              }
            }
          },{
            value:this.userDetails.salariedAmount,
            ProjectMetaKey:{
              connect:{
                id:"6ca5791c-63b2-4005-8486-2e84d180b536"
              }
            }
          },{
            value:this.userDetails.salariedType,
            ProjectMetaKey:{
              connect:{
                id:"ecc79135-687a-4b3d-8d5c-158910900208"
              }
            }
          }]
          console.log("addcustomer");
          await addNewCustomer(this.userDetails);
          this.$router.push({ path: "/dashboard/customers" });
        } else {
          alert("Emailid alredy exist please try another Emailid");
        }
      }
      this.isLoading = false;
    },
  },
});
